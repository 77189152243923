import { yupResolver } from '@hookform/resolvers/yup';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Box, Button, Card, CircularProgress, FormHelperText, Modal, Stack, TextField, Typography } from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { editGallImgAPI } from "../../redux/gallery/editGallImg";
import { getAllGalleryImgsAPI } from '../../redux/gallery/getAllGalleryImgs';
import { getSingleGallImgAPI } from '../../redux/gallery/getSingleGallImg';
import fileUploader from '../../services/upload.service';
import { CloudUpload } from '@mui/icons-material';
import toast from 'react-hot-toast';



const schema = yup.object({
    description: yup.string().required().max(500, "Description cannot be more than 500 characters"),
}).required();



export default function EditGallery({ open, setOpen, galleryId }) {
    const getSingleGallImgSelector = useSelector(state => state?.getSingleGallImg)
    const { result, loading } = getSingleGallImgSelector

    const editGallImgSelector = useSelector(state => state?.editGallImg)
    const { message, error } = editGallImgSelector

    const { register, handleSubmit, setError, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch()



    const [gallImg, setGallImg] = useState('')

    const handleEditbanner = async (data) => {
        await dispatch(editGallImgAPI({ ...data, image: gallImg, galleryId }))
        await dispatch(getAllGalleryImgsAPI())

        setOpen(false)
    }

    const handleFile = async ({ target }) => {
        const files = target.files?.[0]
        const s3Files = await fileUploader.single(files)
        setGallImg(s3Files?.data?.fileUrl)
    }

    const handleClose = () => setOpen(false)

    useEffect(() => {
        dispatch(getSingleGallImgAPI(galleryId))
    }, [dispatch])

    useEffect(()=>{
        if(errors?.description?.message){
          toast.error(errors?.description?.message, {
            position: 'bottom-left', 
            duration: 5000
          })
        }
      }, [errors])


    useEffect(() => {
        setGallImg(result?.image)
        let defaultValues = {};
        defaultValues = {
            description: result?.description
        }
        reset({ ...defaultValues });

    }, [dispatch, result])


    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Box className='modal' style={{ alignItems: "initial" }}>
                    <Card sx={{ p: 2, width: 600 }}>
                        {loading ? <div style={{display: 'flex', justifyContent: "center", width: "100%"}}><CircularProgress /></div> : <form onSubmit={handleSubmit(handleEditbanner)}>
                            <Stack spacing={2}>
                                <Typography variant='h6'>Edit Gallery</Typography>
                                <img style={{ margin: '10px' }} src={gallImg} alt='banner image' />

                                <input
                                    accept=".jpg, .png, .glb, .avif"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    multiple
                                    type="file"
                                    onChange={handleFile}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="outlined" component="span" fullWidth>
                                        Replace Gallery Image<CloudUpload sx={{ ml: 1 }} />
                                    </Button>
                                </label>
                                <FormHelperText error={errors?.bannerError?.message}>{errors?.bannerError?.message}</FormHelperText>

                                <TextField label='Description' {...register('description')} error={errors?.description?.message} helperText={errors?.description?.message} multiline />
                                <Stack direction='row' spacing={2}>
                                    <Button type='submit' variant="contained">Edit</Button>
                                    <Button onClick={handleClose} variant="contained" color="error">Close</Button>
                                </Stack>
                            </Stack>
                        </form>}
                    </Card>
                </Box>
            </Modal>
        </>
    );
}

