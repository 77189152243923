import { Button, CircularProgress, Paper, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React, { useEffect, useState } from "react";
import { CLBGraph } from "./CLBGraph";
import { useDispatch, useSelector } from "react-redux";
import { soldCLBsAPI } from "../../redux/clb/soldCLBs";
import moment from "moment";
import { handleDate } from "../../helpers/date.helper";
import toast from "react-hot-toast";

let queryParams = "";

export default function ClbSolds() {
  const soldCLBsSelector = useSelector((state) => state?.soldCLBs);
  const { result, loading } = soldCLBsSelector;
  const dispatch = useDispatch();

  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const handleSoldCLBs = () => {
    if(!date?.startDate || !date?.endDate){
      toast.error('Date should not be empty!')
      return;
    }
     
    queryParams = `startDate=${date?.startDate}&endDate=${date?.endDate}`;
    dispatch(soldCLBsAPI(queryParams));
  };

  useEffect(() => {
    dispatch(soldCLBsAPI(queryParams));
  }, [dispatch]);

  console.log("result", result)

 
  return (
    <>
      <Paper sx={{ mt: 2, p: 3 }} elevation={0}>
        <Typography variant="h5" fontWeight={600}>
          CLBs Sold
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={["DatePicker", "DatePicker"]}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <DatePicker
              value={date?.startDate}
              label="Start Date"
              disableFuture
              onChange={(newVal) => {
                setDate({
                  ...date,
                  startDate: moment(newVal?.$d)?.format("YYYY-MM-DD"),
                });
              }}
            />
            <DatePicker
              value={date?.endDate}
              label="End Date"
              disableFuture
              onChange={(newVal) =>
                setDate({
                  ...date,
                  endDate: moment(newVal?.$d)?.format("YYYY-MM-DD"),
                })
              }
            />
          </DemoContainer>
          <Button
            onClick={handleSoldCLBs}
            style={{ backgroundColor: "black", color: "white" }}
            sx={{ my: 2 }}
          >
            {loading
                ? <CircularProgress style={{width: '20px', height: '20px'}} /> : 'Get Data'}
          </Button>
        </LocalizationProvider>
        <CLBGraph result={result}/>
      </Paper>
    </>
  );
}
