import { yupResolver } from "@hookform/resolvers/yup";
import { Label } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { addBlogAPI } from "../../redux/Blog/AddBlog";
import { getAllBlogsAPI } from "../../redux/Blog/getAllBlogs";
import { getAllBlogsCategoryAPI } from "../../redux/BlogCategory/getAllBlogsCategory";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  maxHeight: "85vh",
  borderRadius: "10px",
};

const addBlogSchema = yup.object({
  title: yup.string().required("required field"),
  category: yup.string().required("required field"),
  description: yup.string().required("required field"),
});

const AddBlog = () => {
  const getAllBlogsCategorySelector = useSelector(
    (state) => state?.getAllBlogsCategory
  );
  const { result, loading } = getAllBlogsCategorySelector;

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [categoryId, setCategoryId] = useState(null);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(addBlogSchema),
  });

  const handleAddBlog = async (data) => {
    data.category = categoryId;

    await dispatch(addBlogAPI(data));
    await dispatch(getAllBlogsAPI());
    reset();
    handleClose();
  };

  useEffect(() => {
    dispatch(getAllBlogsCategoryAPI());
  }, []);

  return (
    <>
      <div>
        <Button variant="contained" onClick={handleOpen}>
          Add New Blog
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={handleSubmit(handleAddBlog)}>
              <Typography mb={2}> Add New Blog</Typography>
              <Stack spacing={2}>
                <TextField
                  label="Title"
                  {...register("title")}
                  error={errors?.title?.message}
                  helperText={errors?.title?.message}
                />
                <FormControl sx={{ mb: 1 }}>
                  <Autocomplete
                    disablePortal
                    options={result}
                    getOptionLabel={(option) => option.title}
                    onChange={(event, val) => {
                      setCategoryId(val?._id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...register("category")}
                        {...params}
                        label="Category"
                      />
                    )}
                  />
                  <FormHelperText error>
                    {errors?.category?.message}
                  </FormHelperText>
                </FormControl>
                <TextField
                  contentEditable
                  multiline
                  size="medium"
                  label="Description"
                  {...register("description")}
                  errors={errors?.description?.message}
                  helperText={errors?.description?.message}
                  rows={20}
                  style={{ marginTop: "1rem" }}
                />
              </Stack>
              <Stack
                mt={2}
                spacing={2}
                direction={"row"}
                justifyContent={"flex-end"}
              >
                <Button onClick={handleClose} variant="contained">
                  Exit
                </Button>
                <Button type="submit" variant="contained">
                  Add
                </Button>
              </Stack>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default AddBlog;
