import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, useGridSlotComponentProps } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleDate } from "../../helpers/date.helper";
import { getAllSlbsAPI } from "../../redux/slb/getAllSlbs";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import AsignSLB from "./AsignSLB";
import RevertSLBsFromSLB from "./RevertSLBsFromSLB";

function SLBs() {
  const getAllSlbsSelector = useSelector((state) => state?.getAllSlbs);
  const { result, loading } = getAllSlbsSelector;
  const dispatch = useDispatch();

  const [asign, setAsign] = useState(false);
  const [revert, setRevert] = useState(false);
  const [asignData, setAsignData] = useState([]);

  console.log(asignData);

  let columns = [
    {
      field: "title",
      headerName: "Name",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "slbId",
      headerName: "slbId",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return <Typography variant="p">{params?.row?.slbId}</Typography>;
      },
    },
    {
      field: "updated_at",
      headerName: "Date",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="p">
            {handleDate(params?.row?.updated_at)}
          </Typography>
        );
      },
    },
    {
      field: "Buyer",
      headerName: "Buyer",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return <Typography variant="p">{params?.row?.ownerName}</Typography>;
      },
    },
    {
      field: "country",
      headerName: "Country",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "cost.inr",
      headerName: "Sale Price (INR)",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return <Typography variant="p">{params?.row?.cost?.inr}</Typography>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.asigned ? (
              <Typography variant="p" color="primary">
                Assigned
              </Typography>
            ) : (
              "Rejected" && (
                <Typography variant="p" color="error">
                  Not Assigned Yet
                </Typography>
              )
            )}
          </>
        );
      },
    },
  ];

  const selectedSlbs = result.filter((res) => asignData.includes(res._id));
  const selectedSlbsIds = selectedSlbs.map((slb) => slb.slbId);

  useEffect(() => {
    dispatch(getAllSlbsAPI());
  }, []);

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              
              <Typography variant="p">SLBs</Typography>
            </Stack>
            <Box sx={{ display: "flex" }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Button variant="outlined" onClick={() => setRevert(true)}>
                  Revert Back
                </Button>
                <Button
                  variant="outlined"
                  disabled={!asignData?.length}
                  onClick={() => setAsign(true)}
                >
                  Assign
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Paper>

        <Paper sx={{ height: '80%', width: "100%" }} elevation={0}>
          <DataGrid
            getRowId={(row) => row?._id}
            getRowHeight={() => "auto"}
            rows={result}
            columns={columns}
            pageSizeOptions={[25]}
            loading={loading && <CircularProgress />}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            checkboxSelection
            getSelectedRows={(rows) => console.log("rows", rows)}
            onRowSelectionModelChange={(itm) => {
              setAsignData(itm);
            }}
          />
        </Paper>
      </Body>
      <AsignSLB open={asign} setOpen={setAsign} asignData={asignData} />
      <RevertSLBsFromSLB
        open={revert}
        setOpen={setRevert}
        asignData={selectedSlbsIds}
      />
    </>
  );
}

export default SLBs;
