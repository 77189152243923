import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Button, CircularProgress, IconButton, Paper, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDispatch, useSelector } from "react-redux";
import { addAssetsAPI } from "../../redux/assets/addAssets";
import { getAssetsAPI } from "../../redux/assets/getAssets";
import fileUploader from "../../services/upload.service";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";

import { storeAssetsToIpfsAPI } from "../../redux/clb/storeAssetsToIpfs";
import toast from "react-hot-toast";


function Assets() {

    const addAssetsSelector = useSelector(state => state?.addAssets)
    const { message, error } = addAssetsSelector

    const getAssetsSelector = useSelector(state => state?.getAssets)
    const { result, loading } = getAssetsSelector

    const dispatch = useDispatch()



    const handleFile = async ({ target }) => {
        const files = Array.prototype.slice.call(target.files)
        const s3Files = await fileUploader.multiple(files)
        const assetsFile = s3Files?.data?.result
        if (assetsFile) {
            await dispatch(addAssetsAPI(assetsFile))
            await dispatch(getAssetsAPI())
        }

    }

    function copyToClipboard(url) {
        let notificationMessage;
        if (url.length > 30) { 
          const truncatedUrl = `${url.substring(0, 15)}...${url.substring(url.length - 15)}`;
          notificationMessage = `URL ${truncatedUrl} copied`;
        } else {
          notificationMessage = `URL ${url} copied`;
        }
        toast.success(notificationMessage);
    }


    let columns = [
        {
            field: "url",
            headerName: "Asset Url",
            width: 700,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Box>
                        <Typography variant="p" color='primary' style={{ wordWrap: "break-word" }}>{params?.row?.url}</Typography>
                    </Box>

                )
            }
        },
        {
            field: "url_assets",
            headerName: "Assets",
            width: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <>
                        {(params?.row?.url?.includes('.png')
                            || params?.row?.url?.includes('.jpg')
                            || params?.row?.url?.includes('.avif')
                        )
                            && <Box sx={{ p: 4, width: 150 }} ><img src={params?.row?.url} width={'100%'} /></Box >
                        }
                        {params?.row?.url?.includes(".glb") &&
                            <model-viewer
                                style={{ width: '100%', height: '200px' }}
                                src={params?.row?.url}
                                ar-modes="webxr scene-viewer quick-look"
                                camera-controls
                                poster="poster.webp"
                                shadow-intensity="1"
                                disable
                            ></model-viewer>}

                    </>
                )
            }
        },
        {
            field: "link",
            headerName: "Linked / Not Linked",
            width: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <>
                        {params?.row?.link
                            ? <Typography variant="p" color='primary' >Linked</Typography>
                            : <Typography variant="p" color='error'>Not Linked</Typography>
                        }
                    </>
                )
            }
        },
        {
            field: "title",
            headerName: "Copy Url",
            width: 160,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (<>
                    <CopyToClipboard text={params?.row?.url}
                        onCopy={() => {
                           
                            copyToClipboard(params?.row?.url)
                        }}>
                        <Button variant="contained" sx={{ color: '#fff' }} > Copy Url</Button>
                    </CopyToClipboard >
                </>
                )
            }
        },
    ];


    useEffect(() => {
        dispatch(getAssetsAPI())
    }, []);


    return (
        <>
            <SideBar />
            <Body>
                <Appbar />
                <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <Stack direction='row' alignItems='center'>
                            <Typography>Assets</Typography>
                        </Stack>
                        <Stack direction='row' spacing={2} alignItems='center'>
                            <input
                                accept=".jpg, .png, .glb, .mp4, .avif"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                multiple
                                type="file"
                                onChange={handleFile}
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant="outlined" component="span">
                                    Upload Assets<CloudUploadIcon sx={{ ml: 1 }} />
                                </Button>
                            </label>
                        </Stack>
                    </Stack>
                </Paper >


                <Paper
                    sx={{ height: '80%', width: '100%' }} elevation={0}>
                    <DataGrid
                        getRowId={(row) => row._id}
                        getRowHeight={() => 'auto'}
                        rows={result}
                        columns={columns}
                        pageSizeOptions={[25]}
                        disableRowSelectionOnClick
                        loading={loading && <CircularProgress />}
                        disableColumnMenu
                        disableColumnFilter
                        disableColumnSelector
                    />
                </Paper>

            </Body >
        </>
    );
}

export default Assets;
