import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormHelperText,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editBannerAPI } from "../../redux/banner/editBanner";
import { getAllBannersAPI } from "../../redux/banner/getAllBanners";
import { singleBannerAPI } from "../../redux/banner/singleBanner";
import fileUploader from "../../services/upload.service";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

export default function EditBanner({ open, setOpen, bannerId }) {
  const singleBannerSelector = useSelector((state) => state?.singleBanner);
  const { result, loading } = singleBannerSelector;

  const editBannerSelector = useSelector((state) => state?.editBanner);
  const { message, error } = editBannerSelector;

  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({});

  const [banner, setBanner] = useState("");

  const handleEditbanner = async () => {
    await dispatch(editBannerAPI({ banner, bannerId }));
    await dispatch(getAllBannersAPI());

    setOpen(false);
  };

  const handleFile = async ({ target }) => {
    const files = target.files?.[0];
    const s3Files = await fileUploader.single(files);
    setBanner(s3Files?.data?.fileUrl);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(singleBannerAPI(bannerId));
  }, [dispatch]);

  useEffect(() => {
    setBanner(result?.banner);
  }, [dispatch, result]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal" style={{ alignItems: "initial" }}>
          <Card sx={{ p: 2, width: 600 }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <form onSubmit={handleSubmit(handleEditbanner)}>
                <Stack spacing={2}>
                  <Typography variant="h6">Edit Banner</Typography>
                  <img
                    style={{ margin: "10px" }}
                    src={banner}
                    alt="banner image"
                  />

                  <input
                    accept=".jpg, .png, .glb, .mp4, .avif"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={handleFile}
                    width={"600px"}
                  />
                  <label htmlFor="raised-button-file">
                    <Button variant="outlined" component="span" fullWidth>
                      Replace Banner
                      <CloudDownloadIcon sx={{ ml: 1 }} />
                    </Button>
                  </label>
                  <FormHelperText error={errors?.bannerError?.message}>
                    {errors?.bannerError?.message}
                  </FormHelperText>
                  <Stack direction="row" spacing={2}>
                    <Button type="submit" variant="contained">
                      Edit
                    </Button>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="error"
                    >
                      Close
                    </Button>
                  </Stack>
                </Stack>
              </form>
            )}
          </Card>
        </Box>
      </Modal>
    </>
  );
}
