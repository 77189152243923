import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormHelperText,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { assignSlbsToUserAPI } from "../../redux/slb/assignSlbsToUser";
import { getSlbsForOrgAPI } from "../../redux/slb/getSlbsForOrg";
import { getAllSlbsAPI } from "../../redux/slb/getAllSlbs";

const schema = yup
  .object({
    name: yup
      .string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required("Please enter valid name"),
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Please enter valid email"),
    password: yup.string().required('Password is required')
  })
  .required();

function AssignSLBModel({ open, setOpen, slbId }) {
  const [password, setPassword] = useState("");

  const getCurrentAdminSelector = useSelector(
    (state) => state?.getCurrentAdmin
  );

  const adminUser = getCurrentAdminSelector.result;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleGeneratePassword = () => {
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const specialChars = "!@#$%^&*()-_=+";

    const allChars = lowercaseChars + uppercaseChars + numbers + specialChars;

    let generatedPassword = "";
    let i = 0;

    generatedPassword +=
      lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
    generatedPassword +=
      uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
    generatedPassword += numbers[Math.floor(Math.random() * numbers.length)];
    generatedPassword +=
      specialChars[Math.floor(Math.random() * specialChars.length)];

    while (i < 10) {
      generatedPassword +=
        allChars[Math.floor(Math.random() * allChars.length)];
      i++;
    }

    generatedPassword = generatedPassword
      .split("")
      .sort(() => 0.5 - Math.random())
      .join("");

    setPassword(generatedPassword);
    setValue("password",generatedPassword)
  };

  useEffect(()=>{
    console.log(errors);
    
  }, [errors])

  const handleClose = () => setOpen(false);

  const handleSubmitSubmittedData = async (data) => {
    console.log(data);
    

    const formData = {
      ...data,
      password,
      blockType: "slb",
      action: "create",
      slbId,
    };

    await dispatch(assignSlbsToUserAPI(formData));

    if (adminUser.role === "SuperAdmin") await dispatch(getAllSlbsAPI());
    else await dispatch(getSlbsForOrgAPI());
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal">
          <Card sx={{ p: 2, width: 600 }}>
            <form onSubmit={handleSubmit(handleSubmitSubmittedData)}>
              <Stack spacing={2}>
                <Typography variant="h6">
                  Enter Details to Assign SLBs
                </Typography>
                <TextField
                  label="Name*"
                  {...register("name")}
                  error={errors?.name?.message}
                  helperText={errors?.name?.message}
                />
                <TextField
                  label="Email*"
                  {...register("email")}
                  error={errors?.email?.message}
                  helperText={errors?.email?.message}
                />

                <Stack direction="row" spacing={2}>
                  <TextField
                    label="Password*"
                    value={password}
                    // disabled
                    {...register("password")}
                    error={errors?.password?.message}
                    helperText={errors?.password?.message}
                    onChange={(e)=>{setPassword(e.target.value)}}
                  />
                  <Button
                    onClick={handleGeneratePassword}
                    variant="contained"
                    color="primary"
                  >
                    Generate Password
                  </Button>
                </Stack>

                {/* <TextField
                  label="Password"
                  value={password}
                  disabled
                  //   {...register("password")}
                  //   error={errors?.name?.message}
                  //   helperText={errors?.name?.message}
                /> */}
                {/* <Autocomplete
                  multiple
                  disablePortal
                  defaultValue={selectedPermission?.permissions}
                  options={result?.adminPermissions}
                  fullWidth
                  onChange={(e, value) => setPermissions(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Permissions" />
                  )}
                /> */}
                <FormHelperText
                // error={errors?.permssionsError?.message}
                >
                  {/* {errors?.permssionsError?.message} */}
                </FormHelperText>

                <Stack direction="row" spacing={2}>
                  <Button type="submit" variant="contained">
                    Assign
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Card>
        </Box>
      </Modal>
    </>
  );
}

export default AssignSLBModel;
