import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CircularProgress, Modal, Stack, TextField, Typography } from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { sendFeedbackToUserAPI } from '../../redux/feeback/sendFeedbackToUser';
import { getFeedbackDetailAPI } from '../../redux/feeback/getFeedbackDetail';
import { getAllFeedbacksAPI } from '../../redux/feeback/getAllFeedbacks';

const schema = yup.object({
    feedback: yup.string().required("required field"),
    reply: yup.string().required("required field"),
}).required();


function ReplyFeedback({ open, setOpen, feedback }) {

    const [loading, setLoading] = useState(false);

    const getFeedbackDetailSelector = useSelector(state => state?.getFeedbackDetail)
    const { result } = getFeedbackDetailSelector

    const sendFeedbackToUserSelector = useSelector(state => state?.sendFeedbackToUser)
    const { error, message } = sendFeedbackToUserSelector

    const dispatch = useDispatch()

    const { register, handleSubmit, setError, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });
  



    const handleSendFeedback = async (data) => {
        setLoading(true);
        await dispatch(sendFeedbackToUserAPI({ ...data, feedbackId: feedback?._id, userEmail: feedback?.email }))
        await dispatch(getAllFeedbacksAPI())
        setOpen(false)
        setLoading(false);
    }

    const handleClose = () => setOpen(false)

    useEffect(() => {
        dispatch(getFeedbackDetailAPI(feedback?._id))
    }, []);

    useEffect(() => {
        let defaultValues = {};
        defaultValues = {
            feedback: result?.feedback
        }
        reset({ ...defaultValues });
    }, [result])


    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Box className='modal'>
                    <Card sx={{ p: 2, width: 600 }}>
                        <form onSubmit={handleSubmit(handleSendFeedback)}>
                            <Stack spacing={2}>
                                <Typography>Reply</Typography>
                                <TextField label="Feedback" disabled {...register('feedback')} error={errors?.feedback?.message} helperText={errors?.feedback?.message} />
                                <TextField label="Reply"  {...register('reply')} error={errors?.reply?.message} helperText={errors?.reply?.message} />
                                <Stack direction='row' justifyContent='flex-end' spacing={2}>
                                    <Button variant='outlined' onClick={handleClose}>Back</Button>
                                    {
                                        loading ?
                                        <CircularProgress /> :
                                        <Button variant='contained' type='submit'>Send</Button>
                                    }
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>
        </>
    );
}

export default ReplyFeedback;

