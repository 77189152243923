import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../config/authAxios";

const initialState = { loading: true, result: [], error: null, message: "" };

export const getSlbsForOrgAPI = createAsyncThunk(
  "get-slbs-for-org",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`/slbs/current`);
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "get-slbs-for-org",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSlbsForOrgAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSlbsForOrgAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        // state.message = action.payload?.message
      })
      .addCase(getSlbsForOrgAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const getSlbsForOrgReducer = counterSlice.reducer;

export default getSlbsForOrgReducer;
