import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { editCatAPI } from "../../redux/category/editCat";
import { getAllCategoryAPI } from "../../redux/category/getAllCategory";
import { getSingleCatAPI } from "../../redux/category/getSingleCat";

const schema = yup
  .object({
    title: yup.string().required("required field"),
  })
  .required();

function EditCategory({ open, setOpen, id }) {
  const getSingleCatSelector = useSelector((state) => state?.getSingleCat);
  const { result } = getSingleCatSelector;

  const editCatSelector = useSelector((state) => state?.editCat);
  const { message, error } = editCatSelector;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  function validateCategoryInput(input) {
    // Allow only letters, spaces, and specific punctuation (e.g., hyphens and apostrophes)
    const validCharacters = /^[a-zA-Z\s'-]*$/;
    if (!validCharacters.test(input.value)) {
      input.value = input.value.replace(/[^a-zA-Z\s'-]/g, '');
    }
  }

  const handleEditNewPermissionModule = async (data) => {
    await dispatch(editCatAPI({ ...data, catId: id }));
    await dispatch(getAllCategoryAPI());
    setOpen(false);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(getSingleCatAPI(id));
  }, [dispatch]);

  useEffect(() => {
    let defaultValues = {};
    defaultValues = {
      title: result?.title,
    };
    reset({ ...defaultValues });
  }, [dispatch, result]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal">
          <Card sx={{ p: 2, width: 600 }}>
            <form onSubmit={handleSubmit(handleEditNewPermissionModule)}>
              <Stack spacing={2}>
                <Typography variant="h6">Edit </Typography>
                <TextField
                  label="Category"
                  {...register("title")}
                  error={errors?.name?.message}
                  helperText={errors?.name?.message}
                  oninput="validateCategoryInput(this)"
                />
                <Stack direction="row" spacing={2}>
                  <Button type="submit" variant="contained">
                    Save
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Card>
        </Box>
      </Modal>
    </>
  );
}

export default EditCategory;
