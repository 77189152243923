import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DoneIcon from "@mui/icons-material/Done";
import {
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { transferCLBAPI } from "../../redux/clb/transferCLB";
import { getAllOrdersAPI } from "../../redux/orders/getAllOrders";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import RefundWalletAddressModal from "../clbs/RefundWalletAddress";
import TransferClbModel from "./TransferCLB";

function Orders() {
  const getAllOrdersSelector = useSelector((state) => state?.getAllOrders);
  const { result, loading } = getAllOrdersSelector;

  const [refundCLB, setRefundCLB] = useState(false);
  const [transferData, setTransferData] = useState({
    clbId: "",
    walletAddress: ""
  })

  const [csvData, setCsvData] = useState([]);

  const dispatch = useDispatch();

  let columns = [
    {
      field: "item",
      headerName: "CLB",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return <Typography variant="p">{params?.row?.clbId?.title}</Typography>;
      },
    },
    {
      field: "creator",
      headerName: "Creator",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="p">
            {params?.row?.clbId?.creator?.name}
          </Typography>
        );
      },
    },
    {
      field: "sellerId",
      headerName: "Seller",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="p">{params?.row?.sellerId?.name}</Typography>
        );
      },
    },
    {
      field: "buyerId",
      headerName: "Buyer",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="p">{params?.row?.buyerId?.name}</Typography>
        );
      },
    },
    {
      field: "isBidAble",
      headerName: "Is Bidable",
      width: 160,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            {params?.row?.isBidAble ? (
              <Typography variant="p" color="primary">
                Bidable
              </Typography>
            ) : (
              <Typography variant="p" color="error">
                Non Bidable
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "country",
      headerName: "Country",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="p">{params?.row?.clbId?.country}</Typography>
        );
      },
    },
    {
      field: "cost.inr",
      headerName: "Price (INR)",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="p">{params?.row?.clbId?.cost?.inr}</Typography>
        );
      },
    },
    {
      field: "Sale",
      headerName: "Price (USD)",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="p">{params?.row?.clbId?.cost?.usd}</Typography>
        );
      },
    },
    {
      field: "mint",
      headerName: "Mint",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.mint ? (
              <Chip color="primary" label="Minted" />
            ) : (
              <Chip color="warning" label="Due" />
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Payment Status",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.status == "InProgress" && (
              <Chip color="info" label={params?.row?.status} />
            )}
            {params?.row?.status == "Successfull" && (
              // <Chip color="primary" label={params?.row?.status} />
              <Chip color="primary" label="Successful" />

            )}
            {params?.row?.status == "Pending" && (
              <Chip color="warning" label={params?.row?.status} />
            )}
            {params?.row?.status == "Rejected" && (
              <Chip color="error" label={params?.row?.status} />
            )}
          </>
        );
      },
    },
    {
      field: "transfer_nft",
      headerName: "Mint NFT",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            {params?.row?.minted ? (
              <Button
                sx={{ my: 2 }}
                color="primary"
                variant="contained"
                endIcon={<DoneIcon />}
              >
                Minted
              </Button>
            ) : (
              <Button
                sx={{ my: 2 }}
                color="info"
                variant="contained"
                onClick={!params?.row?.clbId?.mint ? () => handleTransferNFT(params?.row) : ()=> handleSetRefund(true, params?.row?.clbId?._id)}
                endIcon={<ArrowRightAltIcon />}
                disabled={!params?.row?.clbId?.mint}
              >
                {!params?.row?.clbId?.mint ? "Minting Required" : "Transfer CLB"}
              </Button>
            )}
          </>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return <>{new Date(params?.row?.created_at).toLocaleDateString()}</>;
      },
    },
  ];

  const handleTransferNFT = async () => {
    await dispatch(
      transferCLBAPI({
        walletAddress: transferData.walletAddress,
        CLBId: transferData.clbId,
      })
    );
  };

  const handleSetRefund = (value, clbId) => {
    if(value){
      setTransferData({
        ...transferData,
        clbId: clbId
      })
    }
    else{
      setTransferData({
        clbId: "",
        walletAddress: ""
      })
    }
    setRefundCLB(value);
  }

  useEffect(() => {
    dispatch(getAllOrdersAPI());
  }, [dispatch]);

  useEffect(() => {
    const orders = [];
    result.map((order) => {
      orders.push({
        id: order._id,
        "CLB Id": order?.clbId?._id,
        "CLB Name": order?.clbId?.title,
        "Buyer Id": order?.buyerId?._id,
        "Buyer Name": order?.buyerId?.name,
        "Seller Id": order?.sellerId?._id,
        "Seller Name": order?.sellerId?.name,
        "Price (INR)": order?.price?.inr,
        "Price (USD)": order?.price?.usd,
        "Transaction Id": order?.transId,
        status: order?.status,
        "Created At": order?.created_at,
      });
    });
    setCsvData(orders);
  }, [result]);

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Stack direction="row" alignItems="center">
            <Stack direction="row" alignItems="center" sx={{ flex: 1 }}>
              <Typography variant="p">Orders</Typography>
            </Stack>
            <CSVLink data={csvData} filename={`orders-${new Date()}.csv`}>
              <Button variant="outlined">
                <CloudDownloadIcon sx={{ mr: 1 }} />
                Download Data
              </Button>
            </CSVLink>
          </Stack>
        </Paper>

        <Paper sx={{ height: '80%', width: "100%" }} elevation={0}>
          <DataGrid
            getRowHeight={() => "auto"}
            getRowId={(row) => row._id}
            rows={result}
            columns={columns}
            pageSizeOptions={[25]}
            disableRowSelectionOnClick
            loading={loading && <CircularProgress />}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </Paper>
        {refundCLB && (
          <TransferClbModel
            open={refundCLB}
            setOpen={handleSetRefund}
            handleSubmit={handleTransferNFT}
            setWalletAddress={setTransferData}
            transferData={transferData}
          />
        )}
      </Body>
    </>
  );
}

export default Orders;
