import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import EditIcon from "@mui/icons-material/Edit";
import { getSingleBlogAPI } from "../../redux/Blog/getSingleBlog";
import { editBlogAPI } from "../../redux/Blog/editBlog";
import { getAllBlogsAPI } from "../../redux/Blog/getAllBlogs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overFlow: "scroll",
  borderRadius: "10px",
  minHeight: "200px",
  overflowY: "scroll",
  maxHeight: "100%",
  margin: "20px 0px"
};

const addBlogSchema = yup.object({
  title: yup.string().required("required field"),
  description: yup.string().required("required field"),
});

const EditBlog = ({ blog, open, setOpen }) => {
  const getSingleBlogAPISelector = useSelector((state) => state?.getSingleBlog);
  const { result, loading } = getSingleBlogAPISelector;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(addBlogSchema),
  });

  const handleClose = () => setOpen(false);

  const handleEditBlog = async (data) => {
    data.blogId = blog?._id;
    await dispatch(editBlogAPI(data));
    await dispatch(getAllBlogsAPI());
  };

  useEffect(() => {
    dispatch(getSingleBlogAPI(blog?._id));
  }, []);

  useEffect(() => {
    let defaultValues = {};
    defaultValues = {
      title: result?.title,
      description: result?.description,
    };

    reset({ ...defaultValues });
  }, [result]);

  return (
    <>
      <div>
        <IconButton variant="contained">
          <EditIcon />
        </IconButton>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            
          }}
        >
          <Box sx={style}>
            <form onSubmit={handleSubmit(handleEditBlog)}>
              <Typography mb={2}>Edit Blog</Typography>
              <Stack spacing={2}>
                <TextField
                  label="Title"
                  {...register("title")}
                  error={errors?.title?.message}
                  helperText={errors?.title?.message}
                />
                <TextField
                  multiline
                  size="medium"
                  label="Description"
                  {...register("description")}
                  errors={errors?.description?.message}
                  helperText={errors?.description?.message}
                  rows={20}
                  style={{
                    marginTop: "1rem",
                  }}
                />
              </Stack>
              <Stack
                mt={2}
                spacing={2}
                direction={"row"}
                justifyContent={"flex-end"}
              >
                <Button onClick={handleClose} variant="contained">
                  Exit
                </Button>
                <Button type="submit" variant="contained">
                  Update
                </Button>
              </Stack>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default EditBlog;
